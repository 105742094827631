'use client';

import { ClientError } from '@/components/client-error';
import { useTranslations } from '@/hooks/useTranslations';

interface ErrorProps {
    error: Error & { digest?: string };
    reset: () => void;
}

export default function Error({ error, reset }: ErrorProps) {
    const translations = useTranslations();

    return (
        <ClientError
            error={error}
            reset={reset}
            translations={translations}
        />
    );
}
