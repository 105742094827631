'use client';

import { useEffect, useCallback } from 'react';

import { ErrorPage } from '@/components/error-page';
import type { Translations } from '@/types';

interface ClientErrorProps {
    error: Error & { digest?: string };
    reset: () => void;
    translations: Translations;
}

export function ClientError({ error, reset, translations }: ClientErrorProps) {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_APP_ENV !== 'production') return;

        const reportError = async () => {
            const Sentry = await import('@sentry/nextjs');
            Sentry.captureException(error);
        };

        reportError().catch(console.error);
    }, [error]);

    const handleReset = useCallback(() => {
        try {
            reset();
        } catch (e) {
            console.error('Failed to reset error boundary:', e);
            window.location.reload();
        }
    }, [reset]);

    return (
        <ErrorPage
            title={translations.error.default.title}
            description={translations.error.default.description}
            actionLabel={translations.error.default.retry}
            onAction={handleReset}
            translations={translations}
        />
    );
}
